import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import LatestUserData from "../action/LatestUserData";
import { profile_pic_path } from "../define/Define";
import UploadResult from "./UploadResult";

function RoomCodeWaiting() {
    const user_data = LatestUserData();
    return (

        <>
            <div className="main-area" style={{ paddingTop: "60px" }}>
                <center className="mt-5 mb-3">
                    <div className="row justify-content-center align-items-center g-2">
                        <div className="col-3">
                            <img
                                className="border-50 img-fluid"
                                src={profile_pic_path + user_data.profile_pic}
                                style={{ aspectRatio: "1/1" }}
                                alt="Profile Pic"
                            />
                            <p className="text-bold m-0">{user_data.uname}</p>
                        </div>
                        <div className="col-2">
                            <img className="border-50 img-fluid" src="/assets/images/vs.png" style={{ aspectRatio: "1/1.5", marginBottom: "10px" }} alt="Profile Pic" />
                        </div>
                        <div className="col-3">
                            <img className="border-50 img-fluid searching_player"
                                src="/assets/images/loader.png"
                                style={{ aspectRatio: "1/1" }}
                                alt="Profile Pic"
                            />
                            <p className="text-bold m-0">-----</p>
                        </div>
                    </div>
                </center>
                <div className="px-4">
                    <div className="wallet-card"
                        style={{ "backgroundColor": "rgb(71, 130, 244, 0.1)", "backgroundImage": "url(/assets/images/bg-wallets-deposit.png)", height: "auto" }}>
                        <div className="text-center" style={{ "fontSize": "1.1em", "color": "rgb(0, 0, 0)", fontWeight: "bold" }}>Loading Room Code...</div>
                        <div className="text-center mt-1">
                            <p className="text-primary m-0" id="roomCodeDiv" style={{ "fontSize": "1.3em", "fontWeight": "900" }}>
                                <img className="border-50 img-fluid searching_player"
                                    src="/assets/images/loader.png"
                                    style={{ aspectRatio: "1/1", width: "25%" }}
                                    alt="Loader Image"
                                />
                            </p>
                        </div>
                    </div>
                </div>
                <p className="text-center mb-0 mt-5">
                    <small>Play Ludo Game in Ludo King App</small>
                </p>
                <div className="row justify-content-center align-items-center g-2">
                    <div className="col-4">
                        <img src="/assets/images/googlePlay.png" className="img-fluid" alt="Google Play Button" />
                    </div>
                    <div className="col-4">
                        <img src="/assets/images/appStore.png" className="img-fluid" alt="Google Play Button" />
                    </div>
                </div>
                <hr />
                <div className="px-4">
                    <div className="mt-3 card" style={{ backgroundColor: "rgba(0,0,0,0.01)" }}>
                        <div className="card-body">
                            <p className="text-center mb-0">Game Rules</p>
                            <div className="m-0 card" style={{ backgroundColor: "rgba(0,0,0,0.001)" }}>
                                <div className="p-2 card-body">
                                    <small>Record every game while playing</small>
                                </div>
                            </div>
                            <div className="m-0 card" style={{ backgroundColor: "rgba(0,0,0,0.001)" }}>
                                <div className="p-2 card-body">
                                    <small>For Cancellation Of Game Video Proof is Necessary.</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}




export default RoomCodeWaiting;
