import { bindActionCreators } from "@reduxjs/toolkit";
import axios from "axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import { ActionCreator } from "../state";

function DeviceValidator() {
    const location = useLocation();
    const dispatcher = useDispatch();
    const logInAction = bindActionCreators(ActionCreator, dispatcher);
    const device_id = secureLocalStorage.getItem("device_id");
    useEffect(() => {
        axios.post("/server/device_checker.php", { device_id }).then(
            data => {
                const response = data.data;
                if (response.status !== 100) {
                    toast.error("Account Logged Out !");
                    setTimeout(() => {
                        logInAction.login(false);
                        secureLocalStorage.clear();
                    }, 1000);
                }
            }
        );
    }, [location]);

    return (
        <></>
    )
}

export default DeviceValidator;