import { bindActionCreators } from "@reduxjs/toolkit";
import axios from "axios";
import { useDispatch } from "react-redux";
import secureLocalStorage from "react-secure-storage";
import { ActionCreator } from "../state";

function LocalLogin() {
    const login_id = secureLocalStorage.getItem("login_id");
    const dispatch = useDispatch();
    const Dispatcher = bindActionCreators(ActionCreator, dispatch);
    axios.post("/server/localLogin.php", { login_id: login_id })
        .then(data => {
            const response = data.data;
            if (response.status === 101) {
                Dispatcher.login(false);
                secureLocalStorage.clear();
            }
            else if (response.status === 100) {
                const wallet_amount = response.msg.wallet;
                const login_id = response.msg.uid;
                const r_wallet = response.msg.reffer_wallet;
                const win_wallet = response.msg.win_wallet;

                Dispatcher.login(login_id);
                secureLocalStorage.setItem("user_data", JSON.stringify(response.msg));
                Dispatcher.UserDataAction(response.msg);
                Dispatcher.AddWalletAmount(wallet_amount, 0);
                Dispatcher.R_WalletAction(r_wallet);
                Dispatcher.Win_WalletAction(win_wallet)
            }
        });
}

export default LocalLogin;