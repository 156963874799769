import { Navigate, useParams } from "react-router-dom"
import secureLocalStorage from "react-secure-storage";

function RefferalLogin() {
    const { id } = useParams();
    secureLocalStorage.setItem("refferal_id", id);
    return (
        <Navigate to={"/login"} />
    )
}

export default RefferalLogin;