export const profile_pic_path = "/upload/";
export const SiteUrl = window.location.origin + "/";
export const Months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
export const PaymentComponents = [
    "order-details",
    "card",
    "app",
    "netbanking"
]

export const GatewayAction = async (url = "", data = {}) => {
    const response = await fetch(url, {
        method: "POST",
        mode: 'cors',
        cache: "no-cache",
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(data),
    });

    return response.json();
}