import { Link } from "react-router-dom";
import { profile_pic_path } from "../../define/Define";

function Sidebar() {
    function make_id(length) {
        let string = '';
        const hashChar = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const hashCharLength = hashChar.length;
        let counter = 0;
        while (counter < length) {
            string += hashChar.charAt(Math.floor(Math.random() * hashCharLength));
            counter++;
        }
        return string;
    }

    return (
        <>
            <div id="overlay" className="overlay"></div>
            <div id="side_bar" className="sideNav hide">
                <Link to="/profile" className="sideNav-options overlay_link">
                    <picture className="sideNav-icon">
                        <img className="" src={profile_pic_path + "profile.png"} alt="Win Cash" />
                    </picture>
                    <div className="position-relative ml-3">
                        <div className="sideNav-text">Profile</div>
                    </div>
                    <picture className="sideNav-arrow">
                        <img src="/assets/images/global-black-chevronRight.png" alt="" />
                    </picture>
                    <div className="sideNav-divider"></div>
                </Link>
                <Link to={`/win-cash/${make_id(15)}`} className="sideNav-options overlay_link">
                    <picture className="sideNav-icon">
                        <img className="" src="/assets/images/gamepad.png" alt="Win Cash" />
                    </picture>
                    <div className="position-relative ml-3">
                        <div className="sideNav-text">Win Cash</div>
                    </div>
                    <picture className="sideNav-arrow">
                        <img src="/assets/images/global-black-chevronRight.png" alt="" />
                    </picture>
                    <div className="sideNav-divider"></div>
                </Link>
                <Link className="sideNav-options overlay_link" to="/wallet">
                    <picture className="sideNav-icon">
                        <img className="" src="/assets/images/wallet.png" alt="My Wallet" />
                    </picture>
                    <div className="position-relative ml-3">
                        <div className="sideNav-text">My Wallet</div>
                    </div>
                    <picture className="sideNav-arrow">
                        <img src="/assets/images/global-black-chevronRight.png" alt="" />
                    </picture>
                    <div className="sideNav-divider"></div>
                </Link>
                <Link className="sideNav-options overlay_link" to="/game-history">
                    <picture className="sideNav-icon">
                        <img className="" src="/assets/images/game-history.png" alt="Games History" />
                    </picture>
                    <div className="position-relative ml-3">
                        <div className="sideNav-text">Games History</div>
                    </div>
                    <picture className="sideNav-arrow">
                        <img src="/assets/images/global-black-chevronRight.png" alt="" />
                    </picture>
                    <div className="sideNav-divider"></div>
                </Link>
                <Link className="sideNav-options overlay_link" to="/transaction-history">
                    <picture className="sideNav-icon">
                        <img className="" src="/assets/images/order-history.png" alt="Transaction History" />
                    </picture>
                    <div className="position-relative ml-3">
                        <div className="sideNav-text">Transaction History</div>
                    </div>
                    <picture className="sideNav-arrow">
                        <img src="/assets/images/global-black-chevronRight.png" alt="" />
                    </picture>
                    <div className="sideNav-divider"></div>
                </Link>
                <Link className="sideNav-options overlay_link" to="/referral">
                    <picture className="sideNav-icon">
                        <img className="" src="/assets/images/refer.png" alt="Refer &amp; Earn" />
                    </picture>
                    <div className="position-relative ml-3">
                        <div className="sideNav-text">Refer &amp; Earn</div>
                    </div>
                    <picture className="sideNav-arrow">
                        <img src="/assets/images/global-black-chevronRight.png" alt="" />
                    </picture>
                    <div className="sideNav-divider"></div>
                </Link>
                <Link className="sideNav-options overlay_link" to="/notification">
                    <picture className="sideNav-icon">
                        <img className="" src="/assets/images/notification.png" alt="Notification" />
                    </picture>
                    <div className="position-relative ml-3">
                        <div className="sideNav-text">Notification</div>
                    </div>
                    <picture className="sideNav-arrow">
                        <img src="/assets/images/global-black-chevronRight.png" alt="" />
                    </picture>
                    <div className="sideNav-divider"></div>
                </Link>
                <Link className="sideNav-options overlay_link" to="/support">
                    <picture className="sideNav-icon">
                        <img className="" src="/assets/images/support.png" alt="Support" />
                    </picture>
                    <div className="position-relative ml-3">
                        <div className="sideNav-text">Support</div>
                    </div>
                    <picture className="sideNav-arrow">
                        <img src="/assets/images/global-black-chevronRight.png" alt="" />
                    </picture>
                    <div className="sideNav-divider"></div>
                </Link>
                <Link className="sideNav-options overlay_link" to="/legal-terms">
                    <picture className="sideNav-icon">
                        <img className="" src="/assets/images/about.png" alt="Support" />
                    </picture>
                    <div className="position-relative ml-3">
                        <div className="sideNav-text">Legal Terms</div>
                    </div>
                    <picture className="sideNav-arrow">
                        <img src="/assets/images/global-black-chevronRight.png" alt="" />
                    </picture>
                    <div className="sideNav-divider"></div>
                </Link>
            </div>
        </>
    )
}

export default Sidebar;