import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import LatestUserData from "../action/LatestUserData";
import { profile_pic_path } from "../define/Define";
import UploadResult from "./UploadResult";


function RoomCode() {
    const user_data = LatestUserData();
    const params = useParams();
    const battle_id = params.battle_id;

    const [battleData, setbattleData] = useState([]);
    useEffect(() => {
        GetBattleData(setbattleData, battle_id);
    }, [battle_id]);

    const copyRoomCode = () => {
        if (window.navigator.clipboard) {
            window.navigator.clipboard.writeText(battleData[0].room_id);
            toast.success('Copied To Clipboard');
        }
        else {
            toast.error('Copy to ClipBoard Not Supported');
        }
    }
    return (
        <>
            {
                battleData.length ? (
                    <>
                        <div className="main-area" style={{ paddingTop: "30px" }}>
                            <center className="mt-5 mb-2">
                                <div className="row justify-content-center align-items-center g-2">
                                    <div className="col-4">
                                        <img
                                            className="border-50 w-50 img-fluid"
                                            src={profile_pic_path + battleData[0].challenger_pic}
                                            style={{ aspectRatio: "1/1" }}
                                            alt="Profile Pic"
                                        />
                                        <p className="text_no_wrap m-0">{battleData[0].challenger}</p>
                                    </div>
                                    <div className="col-2">
                                        <img className="border-50 img-fluid" src="/assets/images/vs.png" style={{ aspectRatio: "1/1.5", marginBottom: "10px" }} alt="Profile Pic" />
                                    </div>
                                    <div className="col-4">
                                        <img className="border-50 w-50 img-fluid"
                                            src={profile_pic_path + battleData[0].accepter_pic}
                                            style={{ aspectRatio: "1/1" }}
                                            alt="Profile Pic"
                                        />
                                        <p className="text_no_wrap m-0">{battleData[0].accepter}</p>
                                    </div>
                                    <div className="col-6 mt-2">
                                        <h6 className="mb-0 fs_7">Bet Amount :</h6>
                                        <p className="mb-2 fs_8"><img height="21px" width="21px" src="/assets/images/global-rupeeIcon.png" alt="GameIcon" />{battleData[0].bet_amount}</p>
                                    </div>
                                </div>
                            </center>
                            <div className="px-4">
                                <div className="wallet-card"
                                    style={{ "backgroundColor": "rgb(71, 130, 244, 0.1)", "backgroundImage": "url(/assets/images/bg-wallets-deposit.png)", height: "auto" }}>
                                    <div className="text-center" style={{ "fontSize": "0.8em", "color": "rgb(0, 0, 0)", fontWeight: "bold" }}>Room Code</div>
                                    <div className="text-center mt-1">
                                        <p className="text-primary m-0" id="roomCodeDiv" style={{ "fontSize": "1.3em", "fontWeight": "900" }}>
                                            {battleData[0].room_id}
                                        </p>
                                        <button className="btn btn-primary btn-sm" onClick={copyRoomCode}>COPY CODE</button>
                                    </div>
                                </div>
                            </div>
                            <p className="text-center mb-0 mt-3">
                                <small>Play Ludo Game in Ludo King App</small>
                            </p>
                            <div className="row justify-content-center align-items-center g-2">
                                <div className="col-4">
                                    <img src="/assets/images/googlePlay.png" className="img-fluid" alt="Google Play Button" />
                                </div>
                                <div className="col-4">
                                    <img src="/assets/images/appStore.png" className="img-fluid" alt="Google Play Button" />
                                </div>
                            </div>
                            <hr />
                            <div className="px-4">
                                <div className="mt-3 card" style={{ backgroundColor: "rgba(0,0,0,0.01)" }}>
                                    <div className="card-body">
                                        <p className="text-center mb-0">Game Rules</p>
                                        <div className="m-0 card" style={{ backgroundColor: "rgba(0,0,0,0.001)" }}>
                                            <div className="p-2 card-body">
                                                <small>Record every game while playing</small>
                                            </div>
                                        </div>
                                        <div className="m-0 card" style={{ backgroundColor: "rgba(0,0,0,0.001)" }}>
                                            <div className="p-2 card-body">
                                                <small>For Cancellation Of Game Video Proof is Necessary.</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="mt-2 mb-5">
                            <p className="text-center mb-0">Your Game Result</p>
                            <UploadResult />
                        </div>
                        <br />
                    </>
                ) : null
            }
        </>
    )
}

function GetBattleData(setbattleData, battle_id) {
    async function caller() {
        const data = await axios.post("/server/APIBattleData.php", { battle_id: battle_id });
        const response = data.data;
        if (response.status === 101) {
            toast.error(response.msg);
        } else if (response.status === 100) {
            setbattleData([response.msg[0]]);
        }
    }
    caller();
}



export default RoomCode;
