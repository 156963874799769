import axios from "axios";
import secureLocalStorage from "react-secure-storage";

function UserData() {
    
    axios.post("/server/user-data-api.php", { user_id: secureLocalStorage.getItem("login_id") })
        .then(data => {
            secureLocalStorage.setItem("user_data", JSON.stringify(data.data));
        });
}
export default UserData;
