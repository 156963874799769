import { toast } from "react-toastify";
import LatestUserData from "../action/LatestUserData";
import { SiteUrl } from "../define/Define";
import { useSelector } from "react-redux";

function Refferal() {
    let user_data = LatestUserData();
    const Wallet = useSelector(state => state.Wallet);
    const R_Wallet = useSelector(state => state.R_Wallet);
    /*/
        // ! {{ Copying Refferal Email Link }} ! //
    /*/
    function CopyRefferLink() {
        window.navigator.clipboard.writeText(user_data.uid);
        toast.success("Reffer Code Copied");
    }

    function shareReferLink() {
        const shareData = {
            title: "RP Game",
            text: `${user_data.uname} Has Invited You To Join RP Game`,
            url: `${SiteUrl}refferalLogin/${user_data.uid}`,
        };
        window.navigator.share(shareData) || toast.error("Share Is not supported in your device");
    }

    return (
        <div className="main-area" style={{ "paddingTop": "60px" }}>
            <div className="px-4 py-4">
                <div className="card">
                    <div className="card-header">
                        <h6 className="card-title text-center mb-0">Your Referral Earnings</h6>
                    </div>
                    <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex flex-column border-end flex-grow-1 align-items-center justify-content-center">
                                <span className="text-capitalize fw-bold" style={{ fontSize: "0.8rem" }}>referred players</span>
                                <b>{user_data.total_referals}</b>
                            </div>
                            <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1">
                                <span className="text-capitalize fw-bold" style={{ fontSize: "0.8rem" }}>Referral Earning</span>
                                <b>₹{R_Wallet}</b>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card mt-4">
                    <div className="card-header">
                        <h6 className="card-title text-center mb-0">How It Works</h6>
                    </div>
                    <div className="card-body p-1">
                        <div className="card">
                            <div className="card-body">
                                <p className="mb-0 text-center">You can refer and <b>Earn 2%</b> of your refferal winning, every time</p>
                            </div>
                        </div>
                        <div className="card my-1">
                            <div className="card-body">
                                <p className="mb-0 text-center">Like if player plays for <b>10000</b> and wins, You will get <b>200</b> as referral amount.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="input-group mt-5 px-2">
                    <input type="text" className="form-control p-2" disabled value={user_data.uid} />
                    <button className="btn btn-primary text-uppercase" onClick={CopyRefferLink}>Copy</button>
                </div>
                <p className="text-uppercase text-center fw-bold fs-3 p-0 m-0 my-3">or</p>
                <div className="row mx-0">
                    <div className="col-6">
                        <a className="btn btn-success w-100">
                            <img src="/assets/images/whatsapp.png" className="img-fluid share_link_img" />
                            <span className="mx-2">WhatsApp</span>
                        </a>
                    </div>
                    <div className="col-6">
                        <a className="btn btn-info w-100">
                            <img src="/assets/images/telegram.png" className="img-fluid share_link_img" />
                            <span className="mx-2">Telegram</span>
                        </a>
                    </div>
                    <div className="col-12 mt-2">
                        <a className="btn btn-primary w-100" onClick={shareReferLink}>
                            <img src="/assets/images/share.png" className="img-fluid clipboard_img" />
                            <span className="mx-2">Share Referral Link </span>
                        </a>
                    </div>
                </div>
            </div>
        </div >
    )
}
export default Refferal;