import { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";

function Home() {
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const handleCloseOffcanvas = (state) => setShowOffcanvas(state);

    return (
        <div className="main-area" style={{ "paddingTop": "60px" }}>
            <section className="games-section p-3">
                <div className="d-flex justify-content-between mt-4 align-items-center games-section-title">
                    {/* <img src="/assets/images/WhiteStroke.png" style={{ aspectRatio: "1/1", width: "20px" }} className="mr-2" alt="" /> */}
                    Join Battle Now !
                    <button onClick={() => handleCloseOffcanvas(!showOffcanvas)} className="btn btn-primary btn-sm">
                        <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="currentColor"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path></svg>
                        Guide
                    </button>
                </div>
                <p className="mt-2 mb-0"><small>Try to decide how good your hand is at a given moment. Nothing else matters. Nothing.</small></p>
                <div className="games-section-headline mb-1">
                    <div className="games-window">
                        <div className="gameCard-container">
                            <span className="blink d-block text-right live_blinker">◉ LIVE</span>
                            <Link className="gameCard" to="/lobby">
                                <picture className="gameCard-image">
                                    <img src="/assets/images/4.jpeg" alt="image4" height="90%" width="100%" />
                                </picture>
                                <div className="gameCard-title">Ludo Classic</div>
                                <picture className="gameCard-icon">
                                    <img src="/assets/images/WhiteStroke.png" alt="WhiteStroke" />
                                </picture>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <div className="col-12 text-right mt-4">
                <Link to="/support" className="btn-md btn btn-success text-white">
                    <img src="/assets/images/whatsapp.png" className="img-fluid share_link_img" />
                    <span className="ml-2 mb-0">Support</span>
                </Link>
            </div>
            <Offcanvas show={showOffcanvas} onHide={() => handleCloseOffcanvas(!showOffcanvas)} animation="slide" placement="bottom">
                <Offcanvas.Header>
                    <Offcanvas.Title className="text-center">How To Play Games & Earn?</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="card h-100">
                        <iframe width="100%" src="https://www.youtube-nocookie.com/embed/" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" style={{ aspectRatio: "16/9", position: "absolute", left: "0px", top: "0px" }}></iframe>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

export default Home;