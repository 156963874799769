import axios from "axios";

export const LoadNotifications = (login_id, callback) => {
    axios.post("/server/loadNotifications.php", { login_id: login_id })
        .then(data => {
            const response = data.data;
            callback(response);
        });
}


export const getUserGameHistory = (callback) => {
    axios.post("/server/LoadGameHistory.php", { status: 100 })
        .then(data => {
            const response = data.data;
            callback(response);
        });
}


export const getUserTransactionHistory = (callback) => {
    axios.post("/server/TransactionHistory.php", { status: 100 })
        .then(data => {
            const response = data.data;
            callback(response);
        });
}

/*/
    // ! {{ Get User WithDraw Requests }} ! //
/*/

export const getWithdrawData = (callback) => {
    axios.post("/server/getWithDrawData.php", { isValid: "isValid" })
        .then(data => {
            const response = data.data;
            callback(response);
        });
}