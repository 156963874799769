import { bindActionCreators } from "@reduxjs/toolkit";
import axios from "axios";
import { cashfreeProd } from "cashfree-pg-sdk-javascript";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import LatestUserData from "../action/LatestUserData";
import { GatewayAction, PaymentComponents } from "../define/Define";
import { ActionCreator } from "../state";






function AddFund() {
    const user_data = LatestUserData();
    const [amount, setAmount] = useState("");
    const [GoingToPay, setGoingToPay] = useState(false);
    const WalletSelector = useSelector(state => state.Wallet);
    const dispatcher = useDispatch();
    const WalletSetter = bindActionCreators(ActionCreator, dispatcher);
    const dropinConfig = {
        components: PaymentComponents,
        onSuccess: function (data) {
            document.getElementById("paymentForm").innerHTML = "";
            setGoingToPay(false);
            const response = data.transaction;
            const ord_id = data.order.orderId;
            const tr_id = response.transactionId;
            const amount = response.transactionAmount;
            const status = data.order.status;
            if (status === "PAID") {
                axios.post("/server/AddFund.php", {
                    ord_id, tr_id, amount
                }).then(data => {
                    const response = data.data;
                    if (response.status === 100) {
                        toast.success(response.msg);
                        WalletSetter.AddWalletAmount(WalletSelector, parseInt(amount));
                        setAmount("");
                    } else if (response.status === 101) {
                        toast.error(response.msg);
                    }
                });

            } else {
                toast.error("Transaction Failed !!");
            }
        },
        onFailure: function (data) {
            toast.error("Payment Failed");
            console.log("Error", data);
        },
        style: {
            backgroundColor: "#fff8eb",
            color: "#ffa200",
            fontSize: "14px",
            fontFamily: "Lato",
            errorColor: "#dd5353",
            theme: "light",
        }
    }

    const openGateway = () => {
        document.getElementById("loadingScreen").classList.add("active");
        GatewayAction("server/CashfreeSession.php", { amount, phone: user_data.phone, email: user_data.uemail })
            .then((data) => {
                document.getElementById("loadingScreen").classList.remove("active");
                if (data.status === 100) {
                    setGoingToPay(true);
                    let cashfree = new cashfreeProd.Cashfree(data.return);
                    setTimeout(() => {
                        cashfree.drop(document.getElementById("paymentForm"), dropinConfig);
                    }, 100);
                } else if (data.status === 101) {
                    toast.error(data.return);
                }
                else {
                    toast.error("FAILED : Error On Proceeding With Gateway");
                }
            });
    }
    
    // const openGateway = () => {
    //     document.getElementById("loadingScreen").classList.add("active");
    //     GatewayAction("server/CashfreeSession.php", { amount, phone: user_data.phone, email: user_data.uemail })
    //         .then((data) => {
    //             document.getElementById("loadingScreen").classList.remove("active");
    //             if (data.status === 100) {
    //                 setGoingToPay(true);
    //                 let cashfree = new cashfreeSandbox.Cashfree(data.return);
    //                 setTimeout(() => {
    //                     cashfree.drop(document.getElementById("paymentForm"), dropinConfig);
    //                 }, 100);
    //             } else if (data.status === 101) {
    //                 toast.error(data.return);
    //             }
    //             else {
    //                 toast.error("FAILED : Error On Proceeding With Gateway");
    //             }
    //         });
    // }
    
    return (
        <>
            <div id="loadingScreen" >
                <div className="text-center">
                    <img src="/assets/images/loader.png" className="img-fluid searching_player" alt="Loading Player" />
                    <h3 className="text-white">Ready To Go</h3>
                </div>
            </div>

            <div className="main-area" style={{ paddingTop: "60px" }}>
                {
                    user_data.uemail ? null : (
                        <span className="d-flex align-items-center profile-wallet bg-light my-4 py-3" style={{ height: "100px" }}>
                            <picture className="ml-4">
                                <img width="32px" src="/assets/images/alerts.png" alt="alert" />
                            </picture>
                            <div className="ml-4 mytext text-muted ">
                                <span className="font-11 text-center w-100">
                                    Email Id Required <br />
                                </span>
                                Plz Update Email Id Before Proceeding ..<br />
                                <Link to="/profile" >CLick Here To Update</Link>
                            </div>
                        </span>
                    )
                }
                {
                    GoingToPay ? (
                        <div id="paymentDiv">
                            <div id="paymentForm"></div>
                        </div>
                    ) : (
                        <div className="px-4 py-3">
                            <div className="games-section">
                                <div className="d-flex position-relative align-items-center">
                                    <div className="games-section-title">Choose amount to add</div>
                                </div>
                            </div>

                            <div className="pb-3">
                                <div className="MuiFormControl-root mt-1 MuiFormControl-fullWidth">
                                    <div className="MuiFormControl-root MuiTextField-root">
                                        <label className="amount_label">Enter Amount</label>
                                        <div className="d-flex" style={{ fontSize: "1.7em", fontWeight: "700" }}>
                                            <div className="mb-0 d-flex align-items-center">
                                                <p className="mb-0 mr-3">₹</p>
                                            </div>
                                            <input type="number" className="MuiInputBase-input MuiInput-input MuiInputBase-inputAdornedStart w-100" value={amount} onChange={e => setAmount(e.target.value)} />
                                        </div>
                                        <p className="MuiFormHelperText-root">Min: 10, Max: 20000</p>
                                    </div>
                                </div>
                                <div className="games-window">
                                    <div className="container mt-4">
                                        <div className="row">
                                            <div className="col-6 my-1">
                                                <div className="card bg-light">
                                                    <div onClick={() => setAmount(100)} className="card-body d-flex justify-content-center p-2 ">
                                                        <div className="collapseCard-title mr-1" style={{ fontSize: "0.9em" }}>₹</div>
                                                        <div className="collapseCard-title" style={{ fontSize: "1.5em" }}>100</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 my-1">
                                                <div onClick={() => setAmount(250)} className="card bg-light">
                                                    <div className="card-body d-flex justify-content-center p-2 ">
                                                        <div className="collapseCard-title mr-1" style={{ fontSize: "0.9em" }}>₹</div>
                                                        <div className="collapseCard-title" style={{ fontSize: "1.5em" }}>250</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 my-1">
                                                <div onClick={() => setAmount(500)} className="card bg-light">
                                                    <div className="card-body d-flex justify-content-center p-2 ">
                                                        <div className="collapseCard-title mr-1" style={{ fontSize: "0.9em" }}>₹</div>
                                                        <div className="collapseCard-title" style={{ fontSize: "1.5em" }}>500</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 my-1">
                                                <div onClick={() => setAmount(1000)} className="card bg-light">
                                                    <div className="card-body d-flex justify-content-center p-2 ">
                                                        <div className="collapseCard-title mr-1" style={{ fontSize: "0.9em" }}>₹</div>
                                                        <div className="collapseCard-title" style={{ fontSize: "1.5em" }}>1000</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="refer-footer">
                                {
                                    amount > 0 ? (
                                        <button className="refer-button cxy w-100 bg-primary" onClick={openGateway}>Next</button>
                                    ) : (
                                        <button className="refer-button cxy w-100 bg-secondary">Next</button>
                                    )
                                }
                            </div>
                        </div>
                    )
                }
            </div >
        </>
    )
}

export default AddFund;