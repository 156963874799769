import { bindActionCreators } from "@reduxjs/toolkit";
import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import LatestUserData from "../action/LatestUserData";
import { ActionCreator } from "../state";

function WithDraw() {
    const user_data = LatestUserData();
    const dispatch = useDispatch();
    const WalletSetter = bindActionCreators(ActionCreator, dispatch);
    const WinWalletBalance = useSelector(state => state.WinWallet);
    const KycValidated = user_data.KycStatus;
    const [WithdrawAmount, setWithdrawSetAmount] = useState(0);
    const [withdrawAcc, setWithdrawAcc] = useState(user_data.withdraw_acc);
    const [formData, setFormData] = useState({
        amount: 0,
        withdrawAcc,
        accountType: "PayTm"
    });

    const handleFormChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const checkValidation = () => {
        var err = {};
        if (formData.amount && formData.amount >= 50) { } else {
            err.msg = "Minimum withdrawal amount Rs.50";
        }
        if (formData.amount <= WinWalletBalance) { } else {
            err.msg = "Not Enough Balance"
        }
        if (formData.accountType) { } else {
            err.msg = "Select Account Types"
        }
        if (formData.withdrawAcc) { } else {
            err.msg = "Account No. Required"
        }
        if (formData.accountType == "Bank Account") {
            if (!formData.ifsc) {
                err.msg = "IFSC Code Required !!"
            }
        } else {
            formData.ifsc = "";
        }
        if (formData.accountType === "PayTm" && formData.withdrawAcc && formData.withdrawAcc.length !== 10) {
            err.msg = "Invalid PayTm Account !!"
        }


        if (err.msg) {
            toast.error(err.msg);
        } else {
            axios.post("/server/WithdrawRequest.php", formData)
                .then(data => {
                    const new_balance = WinWalletBalance - formData.amount
                    const response = data.data;
                    if (response.status === 100) {
                        WalletSetter.Win_WalletAction(new_balance);
                        setFormData({ ...formData, ["amount"]: 0 })
                        setWithdrawSetAmount(0);
                        toast.success(response.msg);
                    }
                    else {
                        toast.error(response.msg);
                    }
                });
        }
    }

    return (
        <div className="main-area" style={{ paddingTop: "70px" }}>
            {
                KycValidated !== 2 ? (
                    <div className="px-4 py-3">
                        <Link className="d-flex align-items-center profile-wallet bg-light m-4 py-3" to="/complete-kyc">
                            <picture className="ml-4">
                                <img width="32px" src="/assets/images/alert.svg" alt="" />
                            </picture>
                            <div className="ml-4 mytext text-muted ">
                                Complete KYC to take Withdrawals
                            </div>
                        </Link>
                    </div>
                ) : (
                    <>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 bg-light d-flex justify-content-around">
                                    <p>Win Wallet Balance</p>
                                    <span className="games-section-title text-success">₹{WinWalletBalance}</span>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="mt-5">
                                <h6 className="mt-4 games-section-title text-center">Choose Account Type</h6>
                                <div className="add-fund-box d-flex align-items-center my-1" style={{ paddingTop: "15px" }}>
                                    <label className="d-flex mb-0 align-items-center w-100" htmlFor="accountType1">
                                        <img width="48px" src="/assets/images/paytm.png" alt="UPI Icon" />
                                        <div className="d-flex justify-content-between w-100 px-3  ml-4">
                                            <div className="jss1">Withdraw to PayTm</div>
                                            <input type="radio" value="PayTm" onChange={e => handleFormChange(e)} id="accountType1" name="accountType" />
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className="add-fund-box d-flex align-items-center my-1" style={{ paddingTop: "15px" }}>
                                <label className="d-flex mb-0 align-items-center w-100" htmlFor="accountType2">
                                    <img width="48px" src="/assets/images/upi.webp" alt="UPI Icon" />
                                    <div className="d-flex justify-content-between w-100 px-3  ml-4">
                                        <div className="jss1">Withdraw to UPI</div>
                                        <input type="radio" value="UPI" onChange={e => handleFormChange(e)} id="accountType2" name="accountType" />
                                    </div>
                                </label>
                            </div>

                            <div className="add-fund-box d-flex align-items-center my-1" style={{ paddingTop: "15px" }}>
                                <label className="d-flex mb-0 align-items-center w-100" htmlFor="accountType3">
                                    <img width="25px" src="/assets/images/bank.png" alt="UPI Icon" />
                                    <div className="d-flex justify-content-between w-100 px-3  ml-4">
                                        <div className="jss1">Withdraw to Bank Account</div>
                                        <input type="radio" value="Bank Account" onChange={e => handleFormChange(e)} id="accountType3" name="accountType" />
                                    </div>
                                </label>
                            </div>
                        </div>

                        <div className="px-4 py-3">
                            <div className="games-section mt-2">
                                <div className="d-flex position-relative align-items-center">
                                    <div className="games-section-title">Fill the details</div>
                                </div>
                            </div>
                            {
                                formData.accountType === "PayTm" ?
                                    (
                                        <>
                                            <label className="mt-4">Enter PayTm Id</label>
                                            <input type="text" name="withdrawAcc" value={formData.withdrawAcc || ""} className="form-control mb-2" autoComplete="off" onInput={e => handleFormChange(e)} placeholder="PayTm ID here" />
                                        </>
                                    ) : formData.accountType === "UPI" ? (
                                        <>
                                            <label className="mt-4">Enter UPI Id</label>
                                            <input type="text" name="withdrawAcc" value={formData.withdrawAcc || ""} className="form-control mb-2" autoComplete="off" onInput={e => handleFormChange(e)} placeholder="UPI ID here" />
                                        </>
                                    ) : formData.accountType === "Bank Account" ? (
                                        <>
                                            <label className="mt-4">Enter Account No.</label>
                                            <input type="text" name="withdrawAcc" value={formData.withdrawAcc || ""} className="form-control mb-2" autoComplete="off" onInput={e => handleFormChange(e)} placeholder="Account No. here" />
                                            <label className="mt-2">Enter IFSC Code</label>
                                            <input type="text" name="ifsc" value={formData.ifsc || ""} className="form-control mb-2" autoComplete="off" onInput={e => handleFormChange(e)} placeholder="IFSC Code here" />
                                        </>
                                    ) : ""
                            }

                            <label>Enter Amount</label>
                            <input type="number" name="amount" id="amount" onInput={e => handleFormChange(e)} value={formData.amount} className="form-control" />

                        </div>
                        <div className="p-3 py-4" style={{ background: "rgb(250, 250, 250, 0.3)" }}>


                            <center>
                                <button className="mt-2 btn btn-success" onClick={checkValidation}>Proceed</button>
                            </center>

                        </div>
                    </>
                )
            }
        </div>
    )
}

export default WithDraw;