function About() {
    return (
        <div className="main-area" style={{ "paddingTop": "60px" }}>
            <div className="card">
                <div className="card-body">
                    <h3 className="text-center mb-4"><span className="bd_btm">About Us</span></h3>
                    <p>RP Game ("or We") is a real-money online gaming product owned and operated by All in one Hax.</p>
                    <p>We are an HTML5 game-publishing company and our mission is to make accessing games fast and easy by removing the friction of app-installs.</p>
                    <p>RP Game is a skill-based real-money gaming platform accessible only for our users in India. It is accessible on https://rpgame.in. On RP Game, users can compete for real cash in Tournaments and Battles. They can encash their winnings via popular options such as Paytm Wallet, UPI or Phonepe.</p>
                    <p>Our Games</p>
                    <p>We have a wide-variety of high-quality, premium HTML5 games, online games. Our games are especially compressed and optimized to work on low-end devices, uncommon browsers, and patchy internet speeds.</p>
                </div>
            </div>
        </div>
    )
}

export default About;