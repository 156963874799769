import { bindActionCreators } from "@reduxjs/toolkit";
import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import UserData from "../action/UserData";
import { ActionCreator } from "../state";
function ReferWallet() {
    const [amount, setAmount] = useState("");
    const Dispatcher = useDispatch();
    const WalletDispartch = bindActionCreators(ActionCreator, Dispatcher);
    const SelectRedeemBalance = useSelector(state => state.R_Wallet);
    const SelectWinBalance = useSelector(state => state.WinWallet);
    const RedeemWalletAmount = (amount) => {
        if (SelectRedeemBalance > amount) {
            axios.post("/server/redeemRefferWallet.php", { amount: amount }).then(data => {
                const response = data.data;
                if (response.status === 100) {
                    toast.success(response.msg);
                    WalletDispartch.R_WalletAction(response.msg.r_wallet);
                    WalletDispartch.Win_WalletAction(parseFloat(SelectWinBalance) + parseFloat(amount));
                    UserData();
                    setAmount("");
                }
            });
        } else {
            toast.error("Insuficient Balance");
        }
    }
    return (
        <div className="main-area" style={{ paddingTop: "80px" }}>
            <div className="px-4 py-3">
                <div className="games-section">
                    <div className="position-relative align-items-center">
                        <div className="games-section-title">Redeem your refer balance</div>
                    </div>
                    <small>TDS (0%) will be deducted after annual referral earning of ₹15,000.</small><br />
                    <small>Enter Amount (Min: 100, Max: 10000)</small>
                </div>
                <div className="pb-3">
                    <div className="MuiFormControl-root mt-4 MuiFormControl-fullWidth">
                        <div className="MuiFormControl-root MuiTextField-root">
                            <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink" data-shrink="true">Enter Amount</label>
                            <div className="d-flex MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl MuiInputBase-adornedStart" style={{ fontSize: "1.7em", fontWeight: "700" }}>
                                <div className="MuiInputAdornment-root MuiInputAdornment-positionStart mb-0">
                                    <p className="MuiTypography-root MuiTypography-body1 MuiTypography-colorTextSecondary mb-0 mx-3">₹</p>
                                </div>
                                <input type="number" className="MuiInputBase-input MuiInput-input MuiInputBase-inputAdornedStart w-100" value={amount} onChange={e => setAmount(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="refer-footer">
                    {
                        amount >= 100 ? (
                            <button disabled="" className="refer-button cxy w-100 bg-primary" onClick={() => RedeemWalletAmount(amount)}>Next</button>
                        ) : (
                            <button disabled="" className="refer-button cxy w-100 bg-secondary" >Next</button>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default ReferWallet;