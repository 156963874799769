import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import LatestUserData from "../action/LatestUserData";
import { LoadNotifications } from "../action/LoadData";
import { UpdateKyc } from "../action/CommonActions";
import UserData from "../action/UserData";
import StateOptions from "./inc/StatesObject";

function CompleteKyc() {
    let user_data = LatestUserData();

    const [documentType, setdocumentType] = useState("");
    const [KycCardNumber, setKycCardNumber] = useState("");
    const [FullName, setFullName] = useState("");
    const [StepNumber, setStepNumber] = useState(1);
    const [DateOfBirth, setDateOfBirth] = useState(new Date().getFullYear() - 3 + "-01-01");
    const [selectedState, setSelectedState] = useState("");
    const [FrontPic, setFrontPic] = useState(null);
    const [BackPic, setBackPic] = useState(null);
    const [KycValidated, setKycValidated] = useState(user_data.KycStatus);
    const [KycBackPic, setKycBackPic] = useState(user_data.kycBackPic);
    const [NotificationList, setNotificationList] = useState(false);
    useEffect(() => {
        LoadNotifications(user_data.uid, (data) => {
            setNotificationList(data);
        });
    }, [user_data.uid]);

    function LogAllData() {
        const SendingParams = [
            { key: "kycType", value: documentType },
            { key: "cardNumber", value: KycCardNumber },
            { key: "kycFullName", value: FullName },
            { key: "dateOfBirth", value: DateOfBirth },
            { key: "state", value: selectedState },
            { key: "kycFrontPic", value: FrontPic },
            { key: "kycBackPic", value: BackPic }
        ]
        UpdateKyc(SendingParams, (data) => {
            if (data.status === 100) {
                UserData();
                setKycValidated(1);
                setKycBackPic(true);
                toast.success(data.msg);
            }
            else if (data.status === 101) {
                toast.error(data.msg);
            }
        });
    }


    function OpneFileSelector(selector) {
        document.getElementById(selector).click();
    }
    return (
        <div className="main-area" style={{ paddingTop: "60px" }}>
            {
                /*/
                    //! If There are Any notification And KYC Verification Is Provoked By Admin
                */
                NotificationList.status === 100 && KycValidated === 0 ?                
                    NotificationList.msg.map((data, index) => {
                        return (
                            data.note_for === "KYC" ? (
                                    index === 0 ? (
                                        <span key={index} className="d-flex align-items-center profile-wallet bg-light my-4 py-3" style={{ height: "100px" }}>
                                            <picture className="ml-4">
                                                <img width="32px" src="/assets/images/alerts.png" alt="alert" />
                                            </picture>
                                            <div className="ml-4 mytext text-muted ">
                                                <span className="font-11 text-center w-100">
                                                    {data.title}<br />
                                                </span>
                                                {data.message}</div>
                                        </span>
                                    ) : null
                            ) : null
                        )
                    })
                    : null
            }
            {
                /*/
                   {{ // ! If Kyc Is Verified By Admin }}
                /*/
                KycValidated === 2 && KycBackPic ? (
                    <div className="cxy flex-column mx-5 mt-5">
                        <picture className="d-flex justify-content-center" style={{ width: "80%", height: "auto" }}>
                            <img src="/assets/images/tick.png" alt="tick" style={{ maxWidth: "100%" }} />
                        </picture>
                        <div className="font-11 mt-4">
                            Your KYC Verification is
                            <span className="text-success"> Completed.</span>
                        </div>
                        <div className="my-3 text-center" style={{ width: "100%" }}>
                            <div className="footer-text" style={{ fontSize: "0.9em" }}>
                                Play Games and Withdraw Your Cash.
                            </div>
                        </div>
                    </div>
                )
                    /*/
                       {{ // ! If Kyc Is Sended For Verfication // Kyc is In Verification Process }}
                    /*/
                    : KycValidated === 1 && KycBackPic ? (
                        <div className="cxy flex-column mx-5 mt-5">
                            <picture className="ml-4" style={{ width: "80%", height: "auto" }}>
                                <img src="/assets/images/kyc-in-process.png" alt="kyc-in-process" style={{ maxWidth: "100%" }} />
                            </picture>
                            <div className="font-11 mt-4">
                                Your KYC is in
                                <span className="text-danger"> Verification</span>
                            </div>
                            <div className="my-3 text-center" style={{ width: "100%" }}>
                                <div className="footer-text" style={{ fontSize: "0.9em" }}>
                                    We are verifying your details. You will be notified when your KYC is completed.
                                </div>
                            </div>
                        </div>
                    )
                        /*/
                            {{ // ! If User Is On 1st Step Of Validation Process ! }}
                        /*/
                        : StepNumber === 1
                            ? (
                                <div className="kycPage">
                                    <div><span style={{ fontSize: "1.5em" }}>Step 1</span> of 3</div>
                                    <p className="mt-2" style={{ color: "rgb(149, 149, 149)" }}>You
                                        need to submit a document that shows that you
                                        are <span style={{ fontWeight: "500" }}>above 18 years</span>
                                        of age and not a resident of <span
                                            style={{ fontWeight: "500" }}>Assam, Odisha, Sikkim,
                                            Nagaland, Telangana, Andhra Pradesh, Tamil
                                            Nadu and Karnataka.</span></p>
                                    <br />
                                    <div>
                                        <span style={{ color: "rgb(149, 149, 149)", fontWeight: "500" }}>Document Type</span>
                                        <select className="kyc-input mt-2 form-control" onChange={e => setdocumentType(e.target.value)} value={documentType} >
                                            <option value="">Select Document</option>
                                            <option value="Adhaar Card">Adhaar Card</option>
                                            <option value="Driving Licsense">Driving Licsense</option>
                                            <option value="Voter Id Card">Voter Id Card</option>
                                        </select>
                                        {
                                            /*/
                                                {{ // ! If KYC document type is selected ! }}
                                            /*/
                                            documentType ? (
                                                <>
                                                    <div style={{ marginTop: "50px" }}>
                                                        <div className="kyc-doc-input input-valid">
                                                            <input type="text" name="docValue" onChange={e => setKycCardNumber(e.target.value)} value={KycCardNumber} className="mt-2" autoComplete="off" fdprocessedid="1h86aq" />
                                                            <div className="label">Enter <b className="text-dark">{documentType}</b> Number</div>
                                                            {
                                                                KycCardNumber.length !== 12 ? (
                                                                    <div className="invalid">Must Be 12 characters</div>
                                                                ) : null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div style={{ paddingBottom: "80px" }}></div>
                                                    {

                                                        /*/
                                                            {{ // ! If KYC document No. is Valid  ! }}
                                                        /*/
                                                        KycCardNumber.length !== 12 ? (
                                                            <div className="refer-footer">
                                                                <button className="refer-button cxy w-100 bg-secondary">Next</button>
                                                            </div>
                                                        ) : (
                                                            <div className="refer-footer">
                                                                <button className="refer-button cxy w-100 bg-primary" onClick={() => setStepNumber(2)}>Next</button>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                                : null
                                        }

                                    </div>

                                </div>
                            )
                            /*/
                                {{ // ! If User Is On 2nd Step Of Validation Process  ! }}
                            /*/
                            : StepNumber === 2
                                ? (
                                    <div className="kycPage">
                                        <div>
                                            <span style={{ fontSize: "1.5em" }}> Step 2</span>
                                            of 3
                                        </div>
                                        <div className="mt-2" style={{ color: "rgb(149, 149, 149)" }}>
                                            Enter details of {documentType} No. :
                                            <span style={{ fontWeight: "500" }}> {KycCardNumber}</span>
                                        </div>
                                        <br />
                                        <div style={{ marginTop: "10px" }}>
                                            <div className="kyc-doc-input">
                                                <input type="text" name="fullName" autoComplete="off" value={FullName} onChange={e => setFullName(e.target.value)} />
                                                <div className="label">
                                                    Full Name
                                                </div>
                                            </div>
                                            <br />
                                            <div className="kyc-doc-input mt-4">
                                                <div className="label">
                                                    Date of Birth</div>
                                                <input id="dob" name="dob" label="Date of Birth" max={new Date().getFullYear() - 3} type="date" value={DateOfBirth} onChange={e => setDateOfBirth(e.target.value)} className="input-date" />
                                            </div>
                                            <div style={{ marginTop: "50px" }}>
                                                <span style={{ color: "rgb(149, 149, 149)", fontWeight: "500" }}> State</span>
                                                <select className="form-control" value={selectedState} onChange={e => setSelectedState(e.target.value)}>
                                                    <option value="">Select State</option>

                                                    {
                                                        StateOptions.map((states, index) => {
                                                            return (
                                                                <option key={index} value={states.value}>{states.value}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>

                                        </div>
                                        <div style={{ paddingbottom: "80px" }}>
                                        </div>
                                        <div className="refer-footer">
                                            <button className="refer-button mr-2 cxy w-100 bg-secondary" onClick={() => setStepNumber(1)} >Previous</button>
                                            {
                                                /*/
                                                    {{ // ! If User has Filled Every Required Data For the form  ! }}
                                                /*/
                                                FullName.length > 5 && DateOfBirth !== new Date().getFullYear() + "-01-01" && selectedState ?
                                                    (
                                                        <button className="refer-button cxy w-100 bg-primary" onClick={() => setStepNumber(3)}>
                                                            Next
                                                        </button>
                                                    ) : (
                                                        <button className="refer-button cxy w-100 bg-secondary">
                                                            Next
                                                        </button>
                                                    )
                                            }
                                        </div>
                                    </div>
                                )
                                /*/
                                    {{ // ! If User Is On 3rd Step Of Validation Process  ! }}
                                /*/
                                : StepNumber === 3
                                    ? (
                                        <div className="kycPage">
                                            <div>
                                                <span style={{ fontSize: "1.5em" }}>Step 3</span>
                                                of 3
                                            </div>
                                            <p className="mt-2" style={{ color: "rgb(149, 149, 149)" }}>
                                                Upload photo of {documentType} :
                                                <span style={{ fontWeight: "500" }}> {KycCardNumber}</span>
                                            </p>
                                            <br />
                                            <div style={{ marginTop: "10px" }}>
                                                <div className="mytext" style={{ fontSize: "1.1em" }}>
                                                    Ensure that your
                                                    <span style={{ fontWeight: "700" }}> name </span>
                                                    , <span style={{ fontWeight: "700" }}> birthdate </span>
                                                    and <span style={{ fontWeight: "700" }}> document number </span>
                                                    are clearly visible in the document photo.
                                                </div>
                                            </div>
                                            <div className="doc-upload mt-5">
                                                <input id="frontPic" name="frontPic" onChange={e => setFrontPic(e.target.files[0])} type="file" accept="image/*" />
                                                {
                                                    /*/
                                                        {{ // ! If User Has Selected From Image Of Document  ! }}
                                                    /*/
                                                    FrontPic ? (
                                                        <div className="uploaded">
                                                            <img src="/assets/images/file-icon.png" width="26px" alt="file-icon" style={{ marginRight: "20px" }} />
                                                            <div className="d-flex flex-column w-80">
                                                                <div className="name">
                                                                    {FrontPic.name}
                                                                </div>
                                                                <div className="size">
                                                                    {FrontPic.size / 1000} KB
                                                                </div>
                                                            </div>
                                                            <div className="image-block" onClick={() => OpneFileSelector("frontPic")}>
                                                                Change
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="cxy flex-column position-absolute">
                                                            <img src="/assets/images/file-uploader-icon.png" width="17px" alt="file-uploader-icon.png" />
                                                            <div className="sideNav-text mt-2">
                                                                Upload Front Photo
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div className="doc-upload mt-5">
                                                <input id="backPic" name="backPic" type="file" onChange={e => setBackPic(e.target.files[0])} accept="image/*" />

                                                {
                                                    /*/
                                                        {{ // ! If User Has Selected Back Image Of Document  ! }}
                                                    /*/
                                                    BackPic ? (
                                                        <div className="uploaded">
                                                            <img src="/assets/images/file-icon.png" width="26px" alt="file-icon" style={{ marginRight: "20px" }} />
                                                            <div className="d-flex flex-column w-80">
                                                                <div className="name">
                                                                    {BackPic.name}
                                                                </div>
                                                                <div className="size">
                                                                    {BackPic.size / 1000} KB
                                                                </div>
                                                            </div>
                                                            <div className="image-block" onClick={() => OpneFileSelector("backPic")}>
                                                                Change
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="cxy flex-column position-absolute">
                                                            <img src="/assets/images/file-uploader-icon.png" width="17px" alt="file-uploader-icon.png" />
                                                            <div className="sideNav-text mt-2">
                                                                Upload Back Photo
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div style={{ paddingBottom: "80px" }}>
                                            </div>
                                            <div className="refer-footer">
                                                <button className="refer-button mr-2 cxy w-100 bg-secondary">
                                                    Previous
                                                </button>
                                                {
                                                    BackPic && FrontPic ?
                                                        (
                                                            <button className="refer-button cxy w-100 bg-primary" onClick={LogAllData}>
                                                                Complete
                                                            </button>
                                                        )
                                                        : (
                                                            <button className="refer-button cxy w-100 bg-secondary" onClick={LogAllData}>
                                                                Complete
                                                            </button>
                                                        )
                                                }
                                            </div>
                                        </div>
                                    ) : null
            }
        </div>
    )
}

export default CompleteKyc;