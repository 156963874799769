import { useEffect, useState } from "react";
import LatestUserData from "../action/LatestUserData";
import { LoadNotifications } from "../action/LoadData";

function Notification() {
    let user_data = LatestUserData();
    const [NotificationList, setNotificationList] = useState(false);
    useEffect(() => {
        LoadNotifications(user_data.uid, (data) => {
            setNotificationList(data);
        });
    }, [user_data.uid]);
    return (
        <div className="main-area" style={{ "paddingTop": "60px" }}>
            {
                /*/
                    // ! {{ Checking If There Are Any Notification or not }} ! //
                /*/
                NotificationList.status === 100 ?
                    NotificationList.msg.map((data, index) => {
                        return (
                            <div key={index} className="d-flex px-3 py-2 list-item">
                                <picture className="mr-3 d-flex align-items-center" >
                                    <img height="36px" width="36px" src="/assets/images/noti_icon.png" alt="kyc-icon-new" />
                                </picture>
                                <div>
                                    <span className="font-9 fw-800 d-flex">
                                        {data.title}
                                    </span>
                                    <small className="d-flex font-7 my-0">
                                        {data.message}
                                    </small>
                                    <small className="d-flex font-7" style={{ color: "rgb(199, 199, 199)" }}>
                                        {data.date}
                                    </small>
                                </div>
                            </div>
                        )
                    })
                    : (
                        <div className="cxy flex-column px-4text-center" style={{ "marginTop": "70px" }}>
                            <img src="/assets/images/nonotification.png" width="220px" alt="nonotification" />
                            <div className="games-section-title mt-4" style={{ "fontSize": "1.2em" }}>No notification yet!</div>
                            <div className="games-section-headline mt-2" style={{ "fontSize": "0.85em" }}>Seems like you havent done any activity yet</div>
                        </div>
                    )
            }
        </div>
    )
}

export default Notification;