import { bindActionCreators } from "@reduxjs/toolkit";
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import LatestUserData from "../action/LatestUserData";
import { getUserTransactionHistory, getWithdrawData } from "../action/LoadData";
import { Months } from "../define/Define";
import { ActionCreator } from "../state";


function TransactionHistory() {
    const user_data = LatestUserData();
    const [UserTransactionHistory, setUserTransactionHistory] = useState([]);
    const [UserWithdrawData, setUserWithdrawData] = useState([]);
    const [ActiveTab, setActiveTab] = useState(0);
    const [activeTr, setActiveTr] = useState("primary");
    const [activeWd, setActiveWd] = useState("secondary");
    const [showModal, handleModalActions] = useState(false);
    const [withdrawModalData, setWithdrawModalData] = useState({});

    const showWithDrawDetails = (data) => {
        handleModalActions(true);
        setWithdrawModalData(data)
    }
    useEffect(() => {
        getUserTransactionHistory(data => {
            setUserTransactionHistory(data.msg);
        });
        getWithdrawData((response) => {
            if (response.status === 100) {
                setUserWithdrawData(response.msg)
            }
        });
    }, [user_data.uid]);
    var cls = "";
    return (
        <div className="main-area" style={{ "paddingTop": "75px" }}>
            <div className="row mx-0 text-center mb-3">
                <div className="col-6 p-1">
                    <div className={`border-bottom border-h-3 border-${activeTr}`} onClick={() => { setActiveTr("primary"); setActiveWd("secondary") }}>
                        Transactions
                    </div>
                </div>
                <div className="col-6 p-1">
                    <div className={`border-bottom border-h-3 border-${activeWd}`} onClick={() => { setActiveTr("secondary"); setActiveWd("primary") }}>
                        Withdrawal
                    </div>
                </div>
            </div>
            {
                activeTr === "primary" ?
                    UserTransactionHistory.length > 0 ? (
                        UserTransactionHistory.map((data, index) => {
                            return (
                                <TransactionFragment key={index} data={data} />
                            )
                        })
                    ) : (
                        <>
                            <span className="d-flex align-items-center profile-wallet bg-light my-4 py-2" style={{ height: "70px" }}>
                                <picture className="ml-4">
                                    <img width="32px" src="/assets/images/alerts.png" alt="alert" />
                                </picture>
                                <div className="ml-4 mytext text-muted ">
                                    <span className="font-11 text-center w-100">No Transaction History Found !!<br /></span>
                                    Play Games !!
                                </div>
                            </span>
                        </>
                    ) : activeWd === "primary" ?
                        UserWithdrawData.length ?
                            UserWithdrawData.map((data, index) => {
                                return (
                                    <WithDrawFragment key={index} data={data} modalFunc={showWithDrawDetails} />
                                )
                            }) : null
                        : null
            }
            <Modal show={showModal} onHide={() => handleModalActions(false)}>
                <Modal.Body>
                    <div className="text-center">
                        <h5 className="mb-3">Withdrawal Details</h5>
                        <h6>Amount : <b id="withDrawAmount">{withdrawModalData.amount || "Not Provided"}</b></h6>
                        <h6>Requested On : <b id="withDrawDate">{withdrawModalData.date || "Not Provided"}</b></h6>
                        <h6>Transaction On : <b id="transactionDate">{withdrawModalData.tr_date || "Not Provided"}</b></h6>
                        <h6>Close Amount : <b id="withDrawCloseAmt">{withdrawModalData.close_amount || "Not Provided"}</b></h6>
                    </div>
                </Modal.Body>
                <Modal.Footer className="p-0 d-flex justify-content-center">
                    <Button variant="primary"
                        onClick={() => {
                            handleModalActions(false)
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

function TransactionFragment(prop) {
    const data = prop.data;
    const { tid, amount, date, type, wallet_type, close_amount } = data;
    const newDate = new Date(date);
    const money_image = type === "DEBIT" ? "/assets/images/global-rupeeIcon.png" : "/assets/images/minusmoney.png";
    const msg_type = type === "DEBIT" ? " To " : " From ";
    return (
        <div className="w-100 py-3 d-flex align-items-center list-item">
            <div className="center-xy list-date mx-2">
                <div>{newDate.getDate()} {Months[newDate.getMonth()]}</div>
                <small>{newDate.getHours()}: {newDate.getMinutes()}</small>
            </div>
            <div className="list-divider-y"></div>
            <div className="mx-3 d-flex list-body">
                <div className="d-flex align-items-center">
                    <picture className="mr-2">
                        <img height="32px" width="32px" src="/assets/images/4.jpeg" alt="GameIcon" style={{ "borderRadius": "5px" }} />
                    </picture>
                </div>
                <div className="d-flex flex-column font-8">
                    <div className="games-section-headline">Cash {type} {msg_type + wallet_type} <b></b>. </div>
                    <div className="games-section-headline">{tid} </div>
                </div>
            </div>
            <div className="right-0 d-flex align-items-end pr-3 flex-column">
                <div className="games-section-headline" style={{ "fontSize": "0.6em" }}>Amount</div>
                <div className="text-right font-8">
                    <picture className="ml-1 mb-1">
                        <img height="21px" width="21px" src={money_image} alt="GameIcon" />
                    </picture>
                    <span className="pl-1" style={{ fontSize: "0.6rem" }}>{amount}</span>
                    <br />
                    <div className="games-section-headline">Closing Amount: {close_amount} </div>
                </div>
            </div>
        </div>
    )
}



function WithDrawFragment(prop) {
    const data = prop.data;
    const modalFunc = prop.modalFunc;
    const { amount, close_amount, date, status, tr_date } = data;
    const dispatcher = useDispatch();
    const WinWalletSetter = bindActionCreators(ActionCreator, dispatcher);
    const WinBalance = useSelector(state => state.WinWallet);

    if (status === 2) {
        var msg = "CANCELLED";
    }
    else if (status === 1) {
        var msg = "COMPLETED";
    } else if (status === 0) {
        var msg = "PENDING";
    }
    const newDate = new Date(date);
    const removeWithdrawReq = (e) => {
        axios.post("/server/cancelWithDraw.php", { withDrawId: data.id })
            .then(data => {
                const response = data.data;
                if (response.status === 100) {
                    toast.success("Request Cancelled.");
                    WinWalletSetter.Win_WalletAction(WinBalance + amount);
                    e.target.setAttribute("disabled", "true");
                }
            });
    }
    return (
        <div className="w-100 py-3 d-flex align-items-center list-item">
            <div className="center-xy list-date mx-2">
                <div>{newDate.getDate()} {Months[newDate.getMonth()]}</div>
                <small>{newDate.getHours()}: {newDate.getMinutes() < 9 ? "0" + newDate.getMinutes() : newDate.getMinutes()}</small>
            </div>
            <div className="list-divider-y"></div>
            <div className="mx-3 d-flex list-body">
                <div className="d-flex align-items-center">
                    <picture className="mr-2">
                        <img height="32px" width="32px" src="/assets/images/4.jpeg" alt="GameIcon" style={{ "borderRadius": "5px" }} />
                    </picture>
                </div>
                <div className="d-flex flex-column font-8">
                    <div className="games-section-headline">Your Withdraw Req. is <b>{msg}</b>. </div>
                    {
                        msg === "COMPLETED" ? (
                            <div className="games-section-headline">
                                <button className="btn btn-primary btn-sm py-0" onClick={() => modalFunc(data)}>
                                    <img src="/assets/images/eye.png" width="10px" className="img-fluid" />
                                </button>
                            </div>
                        ) : msg === "PENDING" ? (
                            <div className="games-section-headline">
                                <button className="btn btn-danger btn-sm py-0" onClick={e => removeWithdrawReq(e)} >
                                    Cancel
                                </button>
                            </div>
                        ) : null
                    }
                </div>
            </div>
            <div className="right-0 d-flex align-items-end pr-3 flex-column">
                <div className="games-section-headline" style={{ "fontSize": "0.6em" }}>Amount</div>
                <div className="text-right font-8">
                    <picture className="ml-1 mb-1">
                        <img height="21px" width="21px" src="/assets/images/global-rupeeIcon.png" alt="GameIcon" />
                    </picture>
                    <span className="pl-1" style={{ fontSize: "0.6rem" }}>{amount}</span>
                    <br />
                    {
                        close_amount ? (
                            <div className="games-section-headline">Closing Amount: {close_amount} </div>
                        ) : null
                    }
                </div>
            </div>
        </div>
    )
}

export default TransactionHistory;
