import axios from "axios";
import UserData from "./UserData";

async function UpdateUserData(field, value, callback = null) {
    await axios.post("/server/UpdateUserData.php", { field: field, value: value })
        .then(data => {
            if (callback !== null) {
                UserData();
                callback(data.data);
            }
        });
}

export default UpdateUserData;