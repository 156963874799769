import axios from "axios";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

function UserBanned() {
    const { mobile } = useParams();
    const navigate = useNavigate();
    const [daysLeft, setDaysLeft] = useState(false);
    const [banData, setBanData] = useState({});

    function getDateDiff(date1, date2) {
        var diffInMs = date2 - date1;
        var diffInSec = diffInMs / 1000;
        var diffInMin = diffInSec / 60;
        var diffInHrs = diffInMin / 60;
        var diffInDays = diffInHrs / 24;

        var years = date2.getFullYear() - date1.getFullYear();
        var months = date2.getMonth() - date1.getMonth();
        var days = date2.getDate() - date1.getDate();

        if (months < 0) {
            years--;
            months += 12;
        }

        if (days < 0) {
            months--;
            var lastMonth = new Date(date2.getFullYear(), date2.getMonth(), 0);
            days += lastMonth.getDate();
        }

        return { years, months, days: days + 1 };
    }

    if (mobile && !banData.banTill) {
        axios.post("/server/bannedData.php", { mobile }).then(
            data => {
                const response = data.data;
                if (response.status === 100) {
                    setBanData(response.msg);
                    var date1 = new Date();
                    var date2 = new Date(response.msg.banTill);
                    var dateDiff = getDateDiff(date1, date2);
                    setDaysLeft(dateDiff);
                } else {
                    navigate("/home");
                }
            }
        );
    }
    return (
        <div className="main-area">
            {
                banData.banMessage ? (
                    <div className="container" style={{ paddingTop: "80px" }}>
                        <div className="row mx-0">
                            <div className="col-12 mt-3 d-flex justify-content-center">
                                <div className="col-4">
                                    <img src="/assets/images/banned.png" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-12 mt-4">
                                <h4 className="text-center text-danger mb-4">This account is temporarily banned  !</h4>
                                <p className="text-center">{banData.banMessage}</p>
                                <div className="row mx-0 pt-4 px-2">
                                    <div className="col-3 text-center">
                                        <h3 className="mb-0">{daysLeft.years}<small style={{fontSize: "12px"}}>Years</small></h3>
                                    </div>
                                    <div className="col-1"><h3>:</h3></div>
                                    <div className="col-3 text-center">
                                        <h3 className="mb-0">{daysLeft.months}<small style={{fontSize: "12px"}}>Months</small></h3>
                                    </div>
                                    <div className="col-1"><h3>:</h3></div>
                                    <div className="col-3 text-center">
                                        <h3 className="mb-0">{daysLeft.days}<small style={{fontSize: "12px"}}>Days</small></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null
            }
        </div>
    )
}

export default UserBanned;