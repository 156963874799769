function Support() {
    return (
        <div className="main-area" style={{ "paddingTop": "90px" }}>
            <div className="cxy flex-column mx-4" style={{ "margiTop": "70px" }}>
                <img src="./assets/images/contact_us.png" width="280px" alt="contact_us" />
                <div className="games-section-title mt-5" style={{ "fontSize": "1.2em" }}>Contact us at below platforms.</div>
                <div className="row mx-0 pt-3 px-4">
                    <div className="col-12 my-1">
                        <a className="btn py-2 btn-success w-100">
                            <img src="/assets/images/whatsapp.png" className="img-fluid share_link_img" />
                            <span className="mx-2">Chat on WhatsApp</span>
                        </a>
                    </div>
                    <div className="col-12 my-1">
                        <a className="btn py-2 insta_bg text-white w-100">
                            <img src="/assets/images/insta_white.png" className="img-fluid share_link_img" />
                            <span className="mx-2">Connect on Instagram</span>
                        </a>
                    </div>
                    <div className="col-12 my-1">
                        <a className="btn py-2 btn-info w-100" href="mailto:info@rpgame.in">
                            <img src="/assets/images/mail.png" className="img-fluid share_link_img" />
                            <span className="mx-2">Reach Us Via Email</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Support;