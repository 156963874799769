import { Accordion } from "react-bootstrap";


function LegalTerms() {
    return (
        <div className="main-area" style={{ "paddingTop": "60px" }}>
            <div className="container pt-3">
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Terms & Conditions</Accordion.Header>
                        <Accordion.Body>
                            These terms and conditions of use (“Terms”) along with privacy policy (“Privacy Policy”) forms a legally binding agreement (“Agreement”) between You and us (Rpgame.in).
                            <br />
                            Hence, We insist that You read these Terms and Privacy Policy and let Us know if You have any questions regarding the same. We will try Our best to answer Your queries.
                            <br />
                            <br />
                            <br />
                            <b>A. USERS’ APPROVAL</b>
                            <br />
                            <br />
                            1. Users approve of and accept over Agreement by:
                            <br />

                            (a) reading all terms and condition
                            <br />

                            (b) reading all rules of this app
                            <br />
                            <br />
                            2. Users may accept this Agreement only if:
                            <br />

                            (a) Such User is a natural person, is of the legal age (18 years or older), eligibility and mental capacity to form a binding contract with us.
                            <br />

                            (b) Such User is a not a resident of Tamil Nadu, Andhra Pradesh, Telangana, Assam, Orissa, Kerala, Sikkim, Nagaland, or Gujarat.
                            <br />

                            (c) Such User is a juristic person, is lawfully existing, and has all the authorizations, permits, and allowances to enter into this Agreement and form a binding contract.
                            <br />

                            (d) Such User is not legally barred or restricted from using the App.
                            <br />
                            <br />
                            3. You understand that We want You to not use the App if You do not understand, approve of or accept all the terms specified in this Agreement. Hence, You are requested to read these Terms and Privacy Policy carefully and understand the Agreement before You accept it and agree to be bound by it.
                            <br />
                            <br />

                            4. The Agreement shall govern the relationship of each User with us. However, We may also execute separate written agreements with its Users. In case of conflict between terms of such separate written agreement and this Agreement, the terms of the separate written agreement shall prevail.
                            <br />
                            <br />
                            <br />
                            <b>B. PROVISION OF THE APP</b>
                            <br />
                            <br />

                            1. Section 12 of the Public Gambling Act, 1867 provides that games of mere skill are exempt from the application of the Act. The Supreme Court of India and various High Courts in India have opined that a game of mere skill is a game “in which, although the element of chance necessarily cannot be entirely eliminated, success depends principally upon the superior knowledge, training, attention, experience and adroitness of the player. A game of skill is one in which the element of skill predominates over the element of chance.” No penalty can be imposed upon a person for playing such games of skill.
                            <br />
                            <br />

                            2. Users must note that ‘Ludo’ game available for challenge in our platform is ‘Games of Skill’, under Indian law, and that we does not support, endorse or offer to Users ‘games of chance’ for money. While ‘Games of Skill’ do not have a comprehensive definition, they are those games where the impact of a player’s effort and skill on the outcome of a game is higher than the impact of luck and chance.
                            <br />
                            <br />

                            3. It may be noted that States are permitted, by the Indian Constitution, to enact laws regulating betting and gambling in their respective jurisdictions. In furtherance of these powers, various States have enacted anti- gambling legislations. Such legislations are largely in concert with the Public Gambling Act of 1867 (and include the exception of “games of skill”). Where a State legislation on gambling exists, it prevails over the Public Gambling Act of 1867. In this regard, the Assam Game and Betting Act, 1970 and Orissa (Prevention of) Gambling Act, 1955 and Telangana State Gaming (Amendment) Ordinance and High Court Judgment in Gujarat, 2017 prohibits games with money stakes and also does not create an exception for games of skill. Therefore, currently, residents of Assam, Odisha, Telangana and Gujarat are not permitted to play on our site.
                            <br />
                            <br />

                            4. The games rules are clearly defined on this platform and Users are encouraged to read, understand and follow these rules to be successful in these games.
                            <br />
                            <br />

                            5. The games on our platform are ‘Games of Skills’, such that the outcome / success in the games is directly dependent on the User’s effort, performance and skill. By choosing how to play, the actions of Users shall have direct impact on the game.
                            <br />
                            <br />

                            6. Every game will have some elements of chance, but in the form of challenges / obstacles that a User would be able to overcome using his/her skills and knowledge of the game. Elements of luck are present in every game to add thrill and excitement, but no two attempts at a game are identical so Users must use their skills in order to be successful
                            <br />
                            <br />

                            7. Since the games available on our platform can be won through Users’ skills and such skills may be enhanced with practice and experience, the performance of a User may improve with time and practice.
                            <br />
                            <br />

                            8. Certain games may have pre-determined outcomes (Ludo), and these outcomes are achievable by Users using their skills.
                            <br />
                            <br />

                            C. GAME RULES
                            <br />

                            1. Player who sets a challenge will share a room id/room code with his/her opponent.
                            <br />
                            <br />

                            2. On winning both players have to update there results in following manner:
                            <br />
                            <br />

                            (a) if you have won, select ‘I Won’ option and upload winning screenshot of the game.
                            <br />

                            (b) if you have lost, select ‘I Lost’ option.
                            <br />

                            (c) if match is not started and both parties doesn't want to play, select ‘Cancel’ option.
                            <br />
                            <br />
                            3. User must have to record every game, and if any player is hacking or cheating a game, contact support.
                            <br />
                            <br />

                            4. If game is not started, if you haven't played a single move yourself, please show the recording of game to support. Game will be cancelled only if you have recording.
                            <br />
                            <br />

                            5. If you don't have any proof against player cheating and error in game, you will be considered as lost.
                            <br />
                            <br />

                            6. If you have not moved a single pawn or no pawn is open at home, your game will be cancelled.
                            <br />
                            <br />

                            7. If your opponent leaves match purposely in starting or initial game, and opponent doesn't have any valid proof for cancellation, you will be awarded win.
                            <br />
                            <br />
                            <br />

                            <b>D. DEPOSIT / WITHDRAWAL</b>
                            <br />
                            <br />

                            1. Players can deposit their balance in Buy Chips section.
                            <br />
                            <br />

                            <b>Important:- If we detect any type of suspecious transaction/activity in your account, in such cases we have rights to Block your account temporarily. Kindly contact Admins in such cases and provided the needed details to Un-block your account.</b>
                            <br />
                            <br />

                            2. Player can take withdrawal by setting a Sell Request on your app.
                            <br />
                            <br />

                            3. Deposit and withdrawal requests are completed by support at any time.
                            <br />
                            <br />

                            4. Any wrong payment detail given by you, will not be considered in refund.
                            <br />
                            <br />

                            5. Once a withdrawal is done, you don't have any authority to raise any query.
                            <br />
                            <br />

                            6. If withdrawal request go on pending, user must have to wait for 1-5 days.
                            <br />
                            <br />

                            E. PENALITY FOR WRONG UPDATES
                            <br />
                            <br />

                            1. If you put the wrong update on any match, you will be charged penality of:
                            <br />

                            (a) if your game is below 1000, penalty will be 10%.
                            <br />

                            (b) if your game is above 1000 and below 5000, penality will be 50 flat.
                            <br />

                            (c) if your game is above 5000 and below 15000, penality will be 100 flat.
                            <br />
                            <br />

                            2. If you don't update result after losing, you will be charged penality of 25 flat.
                            <br />
                            <br />

                            3. Players can have only single account in case multiple accounts found, We have authority to ban those account permanently & add penalty
                            <br />
                            <br />
                            <br />

                            <b>F. COMMISSION CHARGES</b>
                            <br />
                            <br />

                            1. Net 3% commission (after referral money deduction) will be charged on challenge amount.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Cancellation & Refund Policy</Accordion.Header>
                        <Accordion.Body>
                            <b>Refund Policy</b>
                            <br />

                            Thanks for being connected with Rpgame. If you are not entirely satisfied with your subscription, we are here to help.
                            <br />
                            <br />

                            <b>Refunds Process</b>
                            <br />

                            Once we receive your Refund request, we will inspect it and notify you on the status of your refund.
                            <br />
                            <br />

                            If your refund request is approved, we will initiate a refund to your credit card (or original method of payment) within 7 working days. You will receive the credit within a certain amount of days, depending on your card issuer's policies.
                            <br />
                            <br />

                            In case of unforeseen technical glitch, Rpgame would refund subscription upon reviewing the complaint. Final decision lies with the company.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Privacy Policy</Accordion.Header>
                        <Accordion.Body>
                            <b>Introduction</b>
                            <br />
                            <br />

                            This document explains how Rpgame (https://Rpgame.in) collects, processes, stores and/or shares any personal data and/or information from users (jointly referred to as “Information”). By accessing and/or using the Services you consent the collection, transfer, manipulation, storage, disclosure and other uses of your information as described in this Privacy Policy. If you have any concerns about providing data, or having it used in any manner permitted in this Privacy Policy, you should not use the Services. As set out in the terms and conditions relating the Services (the “Terms”), you must be at least at legal age (minimum age of 18 years) to access and/or use the Services, or if legally possible, to access with your legal guardian consent, due authorization and agreement with these Privacy Policy.
                            <br />
                            <br />

                            <b>The Information collected:</b>
                            <br />
                            <br />

                            Rpgame and/or third parties, including but not limited to business partners, advertising networks, analytics or search information providers, may collect and process the following data about you: information that you provide when you fill in forms when accessing and/or using the Services, or when you create an account within the Services; details of your use of the Services and the resources that you access; the correspondence between you and Rpgame and any interactions, or with other users on the Service, the channels and/or any social media related; from third parties who hold data about you and who agree to share this data with us; and information collected via cookies and other similar technologies and/or other mechanisms, as explained further below.
                            <br />
                            <br />

                            Personal Data: While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:
                            <br />
                            <br />

                            * Email address
                            <br />

                            * First name and last name
                            <br />

                            * Phone Number
                            <br />

                            * Address, State, Province, ZIP/Postal code, City
                            <br />
                            <br />

                            I. Cookies: Rpgame uses cookies and other similar technologies in the Services. These technologies operate either by placing a small file which stores some information on your computer or mobile device; and/or by accessing information on your device. Rpgame uses cookies and similar technologies to recognize you and your device, for example by identifying your IP address; to allow the Services to interact with a third party social network or platform where you have chosen to allow such interaction; to allow payment processes when you submit payment instructions; to enable Rpgame and third parties to provide you with more customized services; and to collect data such as your device’s model, operating system and screen size, other applications installed on your device, and information about how you use the Services. By accessing and/or using the Services you consent the use of cookies and similar technologies in accordance with this Privacy Policy. You can disable cookies through your web or mobile device browser settings but some features of the Services may not function properly. Alternatively, if you do not wish such data collection as described in this section, you should stop using the Services.
                            <br />
                            <br />

                            II. Ads: Advertisers on mobile devices sometimes use advertising identifiers to enable and optimize their advertising, to deliver tailor ads related to your interests (Interest-Based Advertising or IBA). These identifiers are non-permanent, non-personal, device identifiers. Rpgame and/or third parties may use your device’s advertising identifier and other information associated with it, to deliver ads that relate to your interests and to improve and measure the effectiveness of ad campaigns.
                            <br />
                            <br />

                            III. Location Information: You may choose to publish your location in your Rpgame profile. You may also tell your location when you enable your device to send such location information. Rpgame and/or third parties may use and store information about your location to provide special features, to deliver ads that relate your interests and/or to improve and customize the Services.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>About Us</Accordion.Header>
                        <Accordion.Body>
                            <p>RP Game ("or We") is a real-money online gaming product owned and operated by All in one Hax.</p>
                            <p>We are an HTML5 game-publishing company and our mission is to make accessing games fast and easy by removing the friction of app-installs.</p>
                            <p>RP Game is a skill-based real-money gaming platform accessible only for our users in India. It is accessible on https://rpgame.in. On RP Game, users can compete for real cash in Tournaments and Battles. They can encash their winnings via popular options such as Paytm Wallet, UPI or Phonepe.</p>
                            <p>Our Games</p>
                            <p>We have a wide-variety of high-quality, premium HTML5 games, online games. Our games are especially compressed and optimized to work on low-end devices, uncommon browsers, and patchy internet speeds.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>Contact Us</Accordion.Header>
                        <Accordion.Body>
                            Cashgram India Private Limited C/O SEEMA 32 CHHITPALGARH PRATAPGARH Pratapgarh UP 230402
                            <br />
                            <br />
                            <a href="mailto:info@rpgame.in">info@rpgame.in</a>
                            <br />
                            <a href="https://rpgame.in">www.rpgame.in</a>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </div>
    )
}
export default LegalTerms;