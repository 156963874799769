import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LatestUserData from "../action/LatestUserData";
import { getUserGameHistory } from "../action/LoadData";
import { Months } from "../define/Define";

function GameHistory() {
    const user_data = LatestUserData();
    const [GameHistoryList, setGameHistoryList] = useState("");
    useEffect(() => {
        getUserGameHistory(data => {
            setGameHistoryList(data);
        });
    }, [user_data.uid]);
    return (
        <div className="main-area" style={{ "paddingTop": "60px" }}>
            {
                GameHistoryList.status === 100 ? (
                    GameHistoryList.msg.slice(0).reverse().map((data, index) => {
                        const date = new Date(data.date);
                        const winner = data.winner === user_data.uid ? true : data.winner;
                        return (
                            <HistoryComponent key={index} date={date} data={data} winner={winner} />
                        )
                    })
                ) : GameHistoryList.status === 101 ? (
                    <div className="cxy flex-column px-4 text-center" style={{ marginTop: "70px" }}>
                        <img src="/assets/images/nogamehistory.png" width="280px" alt="No Game History" />
                        <div className="games-section-title mt-4" style={{ fontSize: "1.2em" }}>
                            No Games Played yet!
                        </div>
                        <div className="games-section-headline mt-2" style={{ fontSize: "0.85em" }}>
                            Seems like you haven`t played any game yet, play now to win!
                        </div>
                    </div>
                ) : null
            }
        </div>
    )
}

export default GameHistory;

function HistoryComponent(prop) {
    const user_data = LatestUserData();
    const { date, data, winner } = prop;
    const game_status = winner === true || winner === user_data.uid ? true : false;

    const money_image = "/assets/images/global-rupeeIcon.png";
    return (
        <div className="w-100 py-3 d-flex align-items-center list-item">
            <div className="center-xy list-date mx-2">
                <div>{date.getDate()} {Months[date.getMonth()]}</div>
                <small>{date.getHours()}: {date.getMinutes()}</small>
            </div>
            <div className="list-divider-y"></div>
            <div className="mx-3 d-flex list-body">
                <div className="d-flex align-items-center">
                    <picture className="mr-2">
                        <img height="32px" width="32px" src="/assets/images/4.jpeg" alt="GameIcon" style={{ "borderRadius": "5px" }} />
                    </picture>
                </div>
                <div className="d-flex flex-column font-8">
                    <div>
                        Played against
                        <b> {data.uname}</b>.
                        {
                            !winner ? (
                                <Link to={"/room_code/" + data.game_id} className="mx-2 text-warning" >Claim Win</Link>
                            ) : game_status ? (
                                <b className="text-success"> - Won</b>
                            ) : (
                                <b className="text-danger"> - Lost</b>
                            )
                        }
                    </div>
                    <div className="games-section-headline">Battle ID: {data.game_id}  </div>
                </div>
            </div>
            <div className="right-0 d-flex align-items-end pr-3 flex-column">
                <div className="games-section-headline" style={{ "fontSize": "0.6em" }}>Bet Amount</div>
                <div className="d-flex float-right font-8">
                    <picture className="ml-1 mb-1">
                        <img height="21px" width="21px" src={money_image} alt="GameIcon" />
                    </picture>
                    <span className="pl-1">{data.bet_amount}</span>
                </div>
            </div>
        </div>
    )
}