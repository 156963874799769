import axios from "axios";
import { toast } from "react-toastify";
import LatestUserData from "./LatestUserData";
import UpdateUserData from "./UpdateUserData";
import UserData from "./UserData";


/*/
    // ! {{ Logout Function }} ! //
/*/

/*/
    // ! {{ Submiting The Form }} ! //
/*/

/*/
    // ! {{ Update KYC Function }} ! //
/*/
export const UpdateKyc = (value, callback) => {
    let formdata = new FormData();
    for (let i = 0; i < value.length; i++) {
        formdata.append(value[i].key, value[i].value);
    }
    axios.post("/server/UpdateUserKyc.php", formdata)
        .then(data => {
            callback(data.data);
        });
}


/*/
    // ! {{ Changing User Profile Pic }} ! //
/*/
export const submitProfileForm = (profile_image) => {
    const fromdata = new FormData();
    fromdata.append("profile_pic", profile_image.fileName);
    axios.post("/server/UpdateProfilePic.php", fromdata).then(
        data => {
            const response = data.data;
            if (response.status === 100) {
                UserData();
                toast.success(response.msg)
            }
            else if (response.status === 101) {
                toast.error(response.msg);
            }
        }
    );
}


/*/
    // ! {{ Changing User Name }} ! //
/*/
export function ChagneUserName(name) {
    let user_data = LatestUserData();
    if (name !== user_data.uname) {
        UpdateUserData("uname", name, (res) => {
            if (res.status === 101) {
                toast.error(res.msg);
            } else if (res.status === 100) {
                toast.success(res.msg);
            }
        });
    }
}


/*/
    // ! {{ Changing User Email }} ! //
/*/
export const chagneUserEmail = (email) => {
    let user_data = LatestUserData();
    if (email !== user_data.uemail) {
        UpdateUserData("uemail", email, (res) => {
            if (res.status === 101) {
                toast.error(res.msg);
            } else if (res.status === 100) {
                toast.success(res.msg);
            }
        });
    }
}


/*/
    // ! {{ Refferal ID Form Submission }} ! //
/*/
export const setUserreferByData = (referCode, setUserReferStatus) => {
    let user_data = LatestUserData();
    if (referCode !== user_data.uid) {
        axios.post("/server/selectAssocUserData.php", { field: "uid", value: referCode })
            .then(data => {
                let response = data.data;
                if (response.status === 100) {
                    UpdateUserData("refer_by", referCode, (res) => {
                        if (res.status === 101) {
                            toast.error(res.msg);
                        } else if (res.status === 100) {
                            setUserReferStatus(true);
                            toast.success(res.msg);
                        }
                    });
                }
                else {
                    toast.error(response.msg);
                }
            });
    }
    else {
        toast.error("This Reffer Id is Not Valid For You");
    }
}


/*/
    // ! {{ Create Custom Challenge }} ! //
/*/
export const createGame = (wallet_amount, battleAmount, setbattleCreated, battleCreated, calldack) => {
    // if (wallet_amount >= battleAmount) {
    if (parseInt(battleAmount) >= 50 && parseInt(battleAmount) <= 10000) {
        axios.post("/server/createBattle.php", { amount: battleAmount })
            .then(data => {
                const response = data.data;
                if (response.status === 100) {
                    UserData();
                    calldack(response);
                    setbattleCreated([...battleCreated, response.msg]);
                    toast.success("Battle Created");
                }
                else if (response.status === 101) {
                    toast.error(response.msg);
                }
            });
    }
    else {
        toast.error("Min Amount is Rs.50 And Max is Rs.10000");
    }
    // }
    // else {
    //     toast.error("Insufficient Balance");
    // }
}

