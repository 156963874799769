// Modules
import { useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Router, Routes, useLocation } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { ToastContainer } from 'react-toastify';

// CSS
import 'react-toastify/dist/ReactToastify.css';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./swiper.css";

// Pages
import { useEffect } from 'react';
import LegalTerms from './pages/LegalTerms';
import About from './pages/About';
import AddFund from './pages/AddFund';
import CompleteKyc from './pages/CompleteKyc';
import GameHistory from './pages/GameHistory';
import Home from './pages/Home';
import Header from './pages/inc/Header';
import RightSide from './pages/inc/RightSide';
import Lobby from './pages/Lobby';
import LocalLogin from './pages/LocalLogin';
import Login from './pages/Login';
import Notification from './pages/Notification';
import Profile from './pages/Profile';
import ReferWallet from './pages/ReferWallet';
import Refferal from './pages/Refferal';
import RefferalLogin from './pages/RefferalLogin';
import RoomCode from './pages/RoomCode';
import RoomCodeWaiting from './pages/RoomCodeWaiting';
import Support from './pages/Support';
import TransactionHistory from './pages/TransactionHistory';
import UploadResult from './pages/UploadResult';
import UserBanned from './pages/UserBanned';
import Wallet from './pages/Wallet';
import WithDraw from './pages/WithDraw';
import DeviceValidator from './action/DeviceValidator';

function App() {
  const login_id = secureLocalStorage.getItem("login_id");
  if (login_id) {
    LocalLogin(login_id);
  }
  const logged = useSelector(state => state.login);

  // secureLocalStorage.clear();


  return (
    <>
      <BrowserRouter>
        <ToastContainer position="bottom-center" theme="colored" />
        <div className="leftContainer">
          <Header />
          {
          logged ?
          (
            <DeviceValidator />
          ) : ""
        }
          <Routes>
            <Route path='/' element={<Home />} />
            {
              logged ? (
                <>
                  {/* Game Related Links */}
                  <Route path='/lobby' element={<Lobby />} />                                 { /* Create And Join Game */}
                  <Route path='/room_code/:battle_id' element={<RoomCode />} />               { /* Join A Game With Room Code */}
                  <Route path='/upload-result/:battle_id' element={<UploadResult />} />       { /* Upload Result After Match is Played */}
                  <Route path='/game-history' element={<GameHistory />} />                    { /* Game Record Player Has Joined */}
                  <Route path='/room_code_waiting' element={<RoomCodeWaiting />} />           { /* Room Code Waiting Area */}

                  {/* Profile Related Links */}
                  <Route path='/profile' element={<Profile />} />                             { /* Edit And View Profile */}
                  <Route path='/complete-kyc' element={<CompleteKyc />} />                    { /* Page For Filling The KYC Page */}
                  <Route path='/notification' element={<Notification />} />                   { /* View Notifications Sended By Admin */}

                  {/* Wallet Related Links */}
                  <Route path='/refer-wallet' element={<ReferWallet />} />                    { /* Withdraw Amount From Refer Wallet*/}
                  <Route path='/wallet' element={<Wallet />} />                               { /* Page To See Wallet Cash And Add Wallet Cash */}
                  <Route path='/referral' element={<Refferal />} />                           { /* Add Wallet Cash */}
                  <Route path='/add-fund' element={<AddFund />} />                            { /* Add Deposit Wallet Cash */}
                  <Route path='/withdraw' element={<WithDraw />} />                           { /* Add Deposit Wallet Cash */}
                  <Route path='/transaction-history' element={<TransactionHistory />} />      { /* Page To View Transaction History */}
                </>
              ) : (
                <>
                  <Route path='/login' element={<Login />} />                                 { /* Login Form Page */}
                  <Route path='/user-banned/:mobile' element={<UserBanned />} />              { /* Login Form Page */}

                </>
              )
            }
            <Route path='/refferalLogin/:id' element={<RefferalLogin />} />                   { /* Page To Set Refferal Id For New Logger */}
            <Route path='/login_success' element={<><Header /> <Navigate to={"/"} /></>} />   { /* Redirecting Directly To Home After Login */}
            <Route path='/support' element={<Support />} />                                   { /* Contact Us And Support Page Route */}
            <Route path='/about' element={<About />} />                                       { /* About Page */}
            <Route path='/legal-terms' element={<LegalTerms />} />                                       { /* About Page */}
            <Route path='*' element={<><Home /></>} />                                        { /* Default Path For NON MATCHING ROUTES */}
          </Routes>
        </div>
        <RightSide />
      </BrowserRouter>
    </>
  );
}
export default App;
