import { bindActionCreators } from "@reduxjs/toolkit";
import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";
import { ActionCreator } from "../state";
function Login() {
    const navigate = useNavigate();
    const dispatcher = useDispatch();
    const [ReadOnly, setReadOnly] = useState("");
    const [ShowResend, setShowResend] = useState(false);
    const LoginAction = bindActionCreators(ActionCreator, dispatcher);
    /*/
       // ! {{ FormData For Login }} ! //
    /*/
    const [formData, setFormData] = useState({
        referalCode: secureLocalStorage.getItem("refferal_id"),
        mobile: "",
    });
    /*/
      // ! {{ Checking If OTP Is Sended Or Not }} ! //
    /*/
    const [otpSent, setOtpSend] = useState("");
    /*/
      // ! {{ Handling The Change for Input }} ! //
    /*/
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }


    /*/
        // ! {{ Submiting The Form }} ! //
    /*/

    const SubmitLoginForm = (e) => {
        let proceed = otpSent && formData.otp ? true : !otpSent ? true : false;
        if (proceed) {
            const submitter = () => {
                axios.post("/server/login.php", formData).then(result => {
                    let response = result.data;
                    if (response.status === 101) {
                        toast.error(response.msg);
                    } else if (response.status === 100) {
                        var timer = 30;
                        toast.success(response.msg);
                        setReadOnly(true);
                        setOtpSend(true);
                        setTimeout(() => {
                            setShowResend(true);
                            clearInterval(timerFunc);
                        }, 30000);
                        const timerFunc = setInterval(() => {
                            document.getElementById("otp_resend_timer").innerHTML = `Resend OTP in : <br /> 0 : ${timer -= 1}`;
                        }, 1000);
                    } else if (response.status === 102) {
                        secureLocalStorage.setItem("login_id", response.msg.user_id);
                        secureLocalStorage.setItem("device_id", response.msg.device_id);
                        LoginAction.login(response.msg.user_id);
                        LoginAction.AddWalletAmount(response.msg.wallet, 0);
                        LoginAction.R_WalletAction(response.msg.r_wallet);
                        LoginAction.Win_WalletAction(response.msg.win_wallet)
                        setTimeout(() => {
                            toast.success("Login Successfull");
                            navigate('/login_success', { replace: true });
                        }, 1000);
                    } else if (response.status === 99) {
                        toast.error(response.msg);
                        setTimeout(() => {
                            navigate(`/user-banned/${formData.mobile}`, { replace: true });
                        }, 1000);

                    }
                    e.target.removeAttribute("disabled");
                });
            }
            submitter();
        }
        else {
            e.target.removeAttribute("disabled");
            toast.error("OTP Required");
        }
    }

    const otpResendfunc = (e) => {
        setReadOnly(false);
        setOtpSend(false);
        setShowResend(false);
        setTimeout(() => {
            document.getElementById("login_btn").click();
        }, 600);
    }

    return (
        <div className="main-area">
            <div style={{ "overflowY": "hidden" }}>
                <div className="splash-overlay"></div>
                <div className="splash-screen">
                    <figure>
                        <img width="100%" src="/assets/images/ludo_bannel_login.png" alt="ludo_bannel_login" />
                    </figure>
                </div>
                <div className="position-absolute mx-auto mt-3" style={{ "top": "5%", "left": "35px", "zIndex": "4" }}>
                    <button className="d-flex mb-4 btn btn-outline-white" onClick={() => window.history.back()}>
                        Back
                    </button>
                </div>
                <div className="position-absolute w-100 center-xy mx-auto" style={{ "top": "40%", "zIndex": "4" }}>
                    <div className="d-flex text-white font-15 mb-4">
                        Sign in or Sign up
                    </div>
                    <div className="bg-white px-4 cxy flex-column" style={{ "width": "85%", "height": "70px", "borderRadius": "5px" }}>
                        <div className="input-group" style={{ "transition": "top 0.5s ease 0s" }}>
                            <div className="input-group-prepend">
                                <div className="input-group-text" style={{ "width": "50px" }}>+91</div>
                            </div>
                            <input className="form-control" name="mobile" type="number" readOnly={ReadOnly} onChange={handleChange} value={formData.mobile} placeholder="Mobile number" fdprocessedid="6ev6qh" style={{ "transition": "all 3s ease-out 0s" }} />
                            <div className="invalid-feedback">
                                Enter a valid mobile number
                            </div>
                        </div>
                    </div>
                    {
                        otpSent ? (
                            <div className="bg-white px-4 cxy flex-column mt-2" style={{ "width": "85%", "height": "70px", "borderRadius": "5px" }}>
                                <div className="input-group pt-2" >
                                    <div className="input-group-prepend">
                                        <div className="input-group-text" style={{ "width": "60px" }}>OTP</div>
                                    </div>
                                    <input className="form-control" name="otp" type="number" onChange={handleChange} value={formData.otp ? formData.otp : ""} placeholder="Enter OTP" fdprocessedid="w59hew" />
                                    <div className="invalid-feedback">Enter a correct OTP
                                    </div>
                                </div>
                            </div>
                        ) : null
                    }
                    <button className="bg-green refer-button cxy mt-4" id="login_btn" onClick={e => { e.target.setAttribute("disabled", true); SubmitLoginForm(e); }} type="submit" fdprocessedid="nepyck" style={{ "width": "85%" }}>Continue</button>
                    {
                        otpSent ?
                            ShowResend ? (
                                <button className="mt-3 btn btn-danger" onClick={e => { e.target.setAttribute("disabled", true); otpResendfunc(e); }}>Resend OTP ?</button>
                            ) : (
                                <>
                                    <p className="text-white text-center mt-3" id="otp_resend_timer"></p>
                                </>
                            )
                            : null
                    }
                </div>
                <div className="login-footer text-white">
                    By proceeding, you agree to our
                    <a className="text-white" href="/term-condition" style={{ "fontSize": "1.1em" }}>Terms of Use</a>,
                    <a className="text-white" href="/privacy-policy" style={{ "fontSize": "1.1em" }}>Privacy Policy</a>
                    and that you are 18 years or older. You are not playing from Assam, Odisha, Nagaland, Sikkim, Meghalaya, Andhra Pradesh, or Telangana.
                </div>
            </div>
        </div>
    )
}

export default Login;