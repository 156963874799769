function RightSide() {
    return (
        <>
            <div className="divider-y"></div>
            <div className="rightContainer">
                <div className="rcBanner flex-center">
                    <picture className="rcBanner-img-container">
                        <img src="/assets/images/logo.png" alt="logo" />
                    </picture>
                    <div className="rcBanner-text">
                        RP Games
                        <span className="rcBanner-text-bold"> Win Real Cash!</span>
                    </div>
                    <div className="rcBanner-footer">
                        For best experience, open&nbsp;
                        <a href="#!" style={{ "color": "rgb(44, 44, 44)", "fontWeight": "500", "textDecoration": "none" }}>Rpgame.in</a>
                        &nbsp;on&nbsp;
                        <img src="/assets/images/global-chrome.png" alt="chrome" />
                        &nbsp;chrome mobile
                    </div>
                </div>
            </div>
        </>
    )
}

export default RightSide;